.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right, #000000, #183D3D, #000000);/* Example background color */
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.carousel-button {
  background-color: #5C8374;
  color: white !important;
  border: none;
  padding: 15px 30px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: #5C8374;
}
.project-video {
  width: 100%;
  height: 400px; /* Increased from 200px */
  max-height: 70vh; /* New: Sets a maximum height relative to the viewport height */
}

.project-container {
  text-align: center;
  max-width: 600px;
  margin: 0 20px;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.titles {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.titles li {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  background-color: #5C8374;
  color: white !important;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.titles li:hover {
  background-color: #0056b3;
}

.selected {
  background-color: #ff5733;
}

@media (max-width: 768px) {
  .project-video {
    height: 200px; /* Smaller height for smaller screens */
  }

  .project-container {
    max-width: 100%; /* Full width on smaller screens */
  }

  .carousel-button {
    padding: 10px 20px; /* Smaller buttons on smaller screens */
  }
}
