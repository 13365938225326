body {
    background: linear-gradient(to right, #000000, #183D3D, #000000) !important;
    color: white !important;
  }  
.bulma_hero__1bjCM.bulma_is-primary__1jh74 {
    background:  #000000 !important;
    color: #fff !important;
}
.bulma_box__1T2Q9 {
    background-color: rgb(0, 0, 0) !important;
}
hr {
    background-color: rgb(255, 255, 255) !important;
    border: none !important;
    display: block !important;
    height: 2px !important;
    margin: 1.5rem 0 !important;
}
.bulma_tag__xgEBq:not(body).bulma_is-info__23I6F {
    background-color: #5C8374 !important;
    color: #fff !important;
}
.bulma_tag__xgEBq:not(body).bulma_is-primary__1jh74 {
    background-color: #5C8374 !important;
    color: #ffffff !important;
}
.bulma_is-rounded__33HAW {
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.6) !important;
}
.footer {
    text-align: center !important;
    color: #ffffff !important;
    opacity: 60% !important;
}
.styles_sectionIcon__1gjLR {
    background: #183D3D !important;
    color: #ffffff !important; /* Replace with your desired color */
}

.styles_sectionContent__v9ofE {
    color:#ffffff !important;
}

.bulma_title__24LjN  {
    color:#ffffff !important;
}