html {
    background: linear-gradient(to right, #000000, #183D3D, #000000);
}
body #contact-form {
    position: absolute;
    background: black;
    color: #fff;
    font-family: sans-serif;
    padding: 20px;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .3), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}
body #contact-form input, body #contact-form textarea {
    width: 100%;
    border: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    margin-top: 15px;
}
body #contact-form textarea {
    height: 200px;
    resize: vertical;
}
body #contact-form button {
    width: 100%;
    padding: 10px;
    border: 0;
    cursor: pointer;
    background: #5C8374;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
body #contact-form span {
    display: none;
    text-align: center;
}
body #contact-form span.visible {
    display: block;
}
