.NavbarItems {
    background: rgba(0, 0, 0, 0);
    height: 55px;
    display: flex;
    padding-right: 15px;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    border-bottom-right-radius: 40px;
}

.navbar-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 120px;
    height: 60px;
    margin-top: 5px;
}

.navbar-brand-name {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    margin-left: 10px;
    color: #fff; /* Adjust the color as needed */
}

.navbar-logo:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 4s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem;
}

.nav-links:hover {
    color: #b3b0b0;
    transition: all 0.7s ease-out;
}

.fa-bars{
    color: #fff;
}

.nav-links-mobile {
    display:none;
}

.menu-icon {
    display:none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
        border-bottom-right-radius: 0px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 800px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: start;
    }

    .nav-menu.active {
        background: rgba(0, 0, 0, 0.79);
        left:0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        width: 100%;
        display: inline;
        font-size: 1.4rem;
    }

    .nav-links:hover {
        color: #008b8b;
        border-radius: 0;
    }

    navbar-logo {
        width: 120px;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: -21px;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: white;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: none;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 250px;
        background: #3acbf7;
        text-decoration: none;
        color: white;
        font-size: 1.6rem;
    }
    .nav-links-mobile:hover {
        color: #6568F4;
        transition: 250ms;
    }
    .navbar-brand-name {
        display: none;
    }
    

}